import Vue from 'vue'
import { mask } from 'vue-the-mask'

let emit = (vnode, name, data) => {
  var handlers =
    (vnode.data && vnode.data.on) ||
    (vnode.componentOptions && vnode.componentOptions.listeners)

  if (handlers && handlers[name]) {
    handlers[name].fns(data)
  }
}

Vue.directive('click-outside', {
  bind: function (el, binding, vNode) {
    // Provided expression must evaluate to a function.
    if (typeof binding.value !== 'function') {
      const compName = vNode.context.name
      let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
      if (compName) {
        warn += `Found in component '${compName}'`
      }

      console.warn(warn)
    }
    // Define Handler and cache it on the element
    const bubble = binding.modifiers.bubble
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e)
      }
    }
    el.__vueClickOutside__ = handler

    // add Event Listeners
    document.addEventListener('click', handler)
  },

  unbind: function (el, binding) {
    // Remove Event Listeners
    document.removeEventListener('click', el.__vueClickOutside__)
    el.__vueClickOutside__ = null
  },
})

Vue.directive('mask', mask)

Vue.directive('tooltip', {
  bind: (el, binding, vnode) => {
    $(el).tooltip()
  },
})

Vue.directive('popover', {
  bind: (el, binding, vnode) => {
    let parameters = _.merge(
      {
        container: '#content',
        template:
          '<div class="popover border-0 shadow-lg" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
      },
      binding.value
    )
    $(el).popover(parameters)

    $(el).on('show.bs.popover', () => {
      emit(vnode, 'show')
    })

    $(el).on('hidden.bs.popover', () => {
      emit(vnode, 'hidden')
    })
  },
})

Vue.directive('validation', {
  bind: (el, binding, vnode) => {
    const element = $(el)

    element?.validate({
      ignore: [],

      submitHandler: (form) => {
        if (binding.arg == 'submit') {
          binding.value()
        }
      },
      invalidHandler: function (e, validation) {
        console.log('invalidHandler : event', e)
        console.log('invalidHandler : validation', validation)
      },
    })

    $(el)
      .find('[required-if]')
      .each(function () {
        var params = $(this).attr('required-if').split('|')
        if (params.length < 2) {
          return false
        }
        $(this).rules('add', {
          required: {
            depends: function (element) {
              if (params[0].indexOf('!') != -1) {
                return $(params[0].replace('!', '')).val() != params[1]
              }
              return $(params[0].replace('!', '')).val() == params[1]
            },
          },
        })
      })

    $(el)
      .find('[equal-to]')
      .each(function () {
        $(this).rules('add', {
          equalTo: $(this).attr('equal-to'),
        })
      })

    $(el)
      .find('[cpf-unique]')
      .each(function () {
        $(this).on('change', (event) => {
          var cpf = $(this).attr('data-value')

          let tipo =
            $(this).attr('cpf-unique') == 'afiliado'
              ? 'afiliados'
              : 'colaboradores'
          $(this).rules('add', {
            remote: {
              url: `${process.env.VUE_APP_API_URL}/${tipo}`,
              type: 'get',
              dataType: 'json',
              data: {
                cpf: cpf,
                bearer_token: vnode.context.$root.$cookie.get('authsession'),
              },
              dataFilter: function (data) {
                if (JSON.parse(data).total_itens == 0) {
                  return true
                }
                return false
              },
            },
            messages: {
              remote: 'CPF já cadastrado',
            },
          })
        })
      })

    $(el)
      .find('[validate-add]')
      .each(function () {
        var p = {}
        p[$(this).attr('validate-add')] = true

        $(this).rules('add', p)
      })
  },
})

Vue.directive('dateRangePicker', {
  bind: (el, binding, vnode) => {
    let defaults = {
      parentEl: 'body',
      template:
        '<div class="daterangepicker dropdown-menu dropdown-menu-right">' +
        '<div class="calendar left text-white">' +
        `<div class="daterangepicker_input">
                        <input class="input-mini form-control active" type="text" name="daterangepicker_start" value="" autocomplete="off">
                        <i class="fa fa-calendar glyphicon glyphicon-calendar"></i>
                        <div class="calendar-time" style="display: none;">
                            <div></div>
                            <i class="fa fa-clock-o glyphicon glyphicon-time"></i>
                        </div>
                    </div>` +
        '<div class="daterangepicker_input">' +
        '<div class="calendar-time">' +
        '<div></div>' +
        '<i class="fa fa-clock-o glyphicon glyphicon-time"></i>' +
        '</div>' +
        '</div>' +
        '<div class="calendar-table rounded shadow-sm"></div>' +
        '</div>' +
        '<div class="calendar right text-white">' +
        `<div class="daterangepicker_input">
                    <input class="input-mini form-control" type="text" name="daterangepicker_end" value="" autocomplete="off">
                    <i class="fa fa-calendar glyphicon glyphicon-calendar"></i>
                    <div class="calendar-time" style="display: none;">
                        <div></div>
                        <i class="fa fa-clock-o glyphicon glyphicon-time"></i>
                    </div>
                </div>` +
        '<div class="daterangepicker_input">' +
        '<div class="calendar-time">' +
        '<div></div>' +
        '<i class="fa fa-clock-o glyphicon glyphicon-time"></i>' +
        '</div>' +
        '</div>' +
        '<div class="calendar-table shadow-sm"></div>' +
        '</div>' +
        '<div class="ranges">' +
        '<div class="range_inputs">' +
        '<button class="applyBtn btn btn-secondary" disabled="disabled" type="button"></button> ' +
        '<button class="cancelBtn btn-link" type="button"></button>' +
        '</div>' +
        '</div>' +
        '</div>',
      ranges: {
        Hoje: [moment(), moment()],
        Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Últimos 7 Dias': [moment().subtract(6, 'days'), moment()],
        'Últimos 30 Dias': [moment().subtract(29, 'days'), moment()],
        'Esse Mês': [moment().startOf('month'), moment().endOf('month')],
        'Esse Ano': [moment().startOf('year'), moment().endOf('year')],
        'Ano passado': [
          moment().subtract('1', 'year').startOf('year'),
          moment().subtract('1', 'year').endOf('year'),
        ],
        'Mês Passado': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
        'Últimos 6 Meses': [
          moment().subtract(6, 'month').startOf('month'),
          moment().endOf('month'),
        ],
        //'Últimos 3 Meses': [moment().subtract(3, 'month').startOf('month'), moment().endOf('month')],
        'Últimos 2 Meses': [
          moment().subtract(2, 'month').startOf('month'),
          moment().endOf('month'),
        ],
      },

      alwaysShowCalendars: true,
      applyClass: 'btn-secondary text-white',
      cancelClass: 'btn-link text-white',
      showCustomRangeLabel: false,
      locale: {
        customRangeLabel: 'Customizado',
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        format: 'DD/MM/YYYY',
        separator: ' - ',
      },
    }

    defaults = $.extend(defaults, binding.value)

    defaults.locale = {
      customRangeLabel: 'Customizado',
      applyLabel: 'Aplicar',
      cancelLabel: 'Cancelar',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    }

    if (defaults.startDate && _.get(binding.value, 'locale.format')) {
      defaults.startDate = moment(
        defaults.startDate,
        _.get(binding.value, 'locale.format')
      ).format('DD/MM/YYYY')
    }

    if (defaults.endDate && _.get(binding.value, 'locale.format')) {
      defaults.endDate = moment(
        defaults.endDate,
        _.get(binding.value, 'locale.format')
      ).format('DD/MM/YYYY')
    }

    $(el).daterangepicker(defaults)

    $(el).on('apply.daterangepicker', (ev, picker) => {
      emit(vnode, 'change', { event: ev, picker: picker })
    })

    $(el).on('show.daterangepicker', (ev, picker) => {
      $('[name="daterangepicker_start"]').mask('00/00/0000')
      $('[name="daterangepicker_end"]').mask('00/00/0000')
      //emit(vnode, 'change' , {event: ev, picker: picker});
    })

    $(el).on('click', (e) => {
      e.preventDefault()
    })
  },
})

Vue.directive('datePicker', {
  bind: (el, binding, vnode) => {
    let defaults = {
      parentEl: 'body',
      // template: '<div class="daterangepicker dropdown-menu dropdown-menu-right">' +
      //     '<div class="calendar left text-white">' +
      //         '<div class="daterangepicker_input">' +
      //           '<div class="calendar-time">' +
      //             '<div></div>' +
      //             '<i class="fa fa-clock-o glyphicon glyphicon-time"></i>' +
      //           '</div>' +
      //         '</div>' +
      //         '<div class="calendar-table rounded shadow-sm"></div>' +
      //     '</div>' +
      //     '<div class="calendar right text-white">' +
      //         '<div class="daterangepicker_input">' +
      //           '<div class="calendar-time">' +
      //             '<div></div>' +
      //             '<i class="fa fa-clock-o glyphicon glyphicon-time"></i>' +
      //           '</div>' +
      //         '</div>' +
      //         '<div class="calendar-table shadow-sm"></div>' +
      //     '</div>' +
      //     '<div class="ranges">' +
      //         '<div class="range_inputs">' +
      //             '<button class="applyBtn btn btn-secondary" disabled="disabled" type="button"></button> ' +
      //             '<button class="cancelBtn btn-link" type="button"></button>' +
      //         '</div>' +
      //     '</div>' +
      // '</div>',
      singleDatePicker: true,
      alwaysShowCalendars: true,
      applyClass: 'btn-secondary text-white',
      cancelClass: 'btn-link text-white',
      locale: {
        customRangeLabel: 'Customizado',
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        format: 'DD/MM/YYYY',
        daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        monthNames: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ],
      },
    }

    if (!binding.value.startDate) {
      binding.value.startDate = moment().format(
        _.get(binding.value, 'locale.format', 'DD/MM/YYYY')
      )
    }

    defaults = $.extend(defaults, binding.value)

    $(el).daterangepicker(defaults)

    $(el).on('apply.daterangepicker', (ev, picker) => {
      emit(vnode, 'change', { event: ev, picker: picker })
    })

    $(el).on('click', (e) => {
      e.preventDefault()
    })
  },
})

Vue.directive('datePickerBootstrap', {
  bind: (el, binding, vnode) => {
    let defaults = $.extend(
      {
        language: 'pt-BR',
      },
      binding.value
    )

    $(el)
      .datepicker(defaults)
      .on('changeDate', function (e) {
        emit(vnode, 'changeDate', e.date)
      })
  },
})
